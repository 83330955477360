export const formatPhone = (phone) => {
  let array = phone.split("");

  array.splice(0, 0, "+7 (");
  array.splice(4, 0, ") ");
  array.splice(8, 0, "-");
  array.splice(11, 0, "-");

  return array.join("");
};

export const formatStatusProfile = (type) => {
  switch (type) {
    case 0:
      return "Физическое лицо";
    case 1:
      return "Индивидуальный предприниматель";
    case 2:
      return "Юридическое лицо";
    default:
      return "Не указано";
  }
};

export const formatStatusApplication = (type) => {
  switch (type) {
    case 0:
      return "Открыта";
    case 1:
      return "Формирование задания";
    case 2:
      return "Согласование документов";
    case 3:
      return "Подписание документов";
    case 4:
      return "Исследование площади";
    case 5:
      return "Обработка результатов исследования";
    case 6:
      return "В процессе выполнения";
    case 7:
      return "Закрыта";
    default:
      return null;
  }
};

export const formatStatusRole = (type =null, roles = []) => {
  if (type === null || type === undefined) {
    type = 0;

    if (roles.includes('ROLE_MANAGER')) type = 1;
    if (roles.includes('ROLE_ADMIN')) type = 2;
  }

  switch (type) {
    case 0:
      return "Пользователь";
    case 1:
      return "Менеджер";
    case 2:
      return "Администратор";
    default:
      return "Не указано";
  }
};

export const countTypesOfStatusApp = 8;
export const countTypesOfRole = 3;
export const getColorOfStatusApp = (type) => {
  switch (type) {
    case 0:
    case 4:
    case 6:
      return "green";
    case 1:
    case 2:
    case 3:
    case 5:
      return "yellow";
    case 7:
      return "red";
    default:
      return null;
  }
};

export const getRole = (roles) => {
  roles = Array.isArray(roles) ? roles : Object.values(roles);
  if (roles.includes("ROLE_ADMIN")) return 2;
  if (roles.includes("ROLE_MANAGER")) return 1;
  return 0;
};
export const formatServerRole = (roleId) => {
  let role = null;
  switch (roleId) {
    case 0:
      role = 'ROLE_USER';
      break;
    case 1:
      role = 'ROLE_MANAGER';
      break;
    case 2:
      role = 'ROLE_ADMIN';
      break;
  }

  return role;
};

export const toISOStringDate = (date) => {
  const newDate = new Date(date);
  console.log(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  return date ? newDate.toISOString() : undefined;
};

export const formatDate = (date, splitter = ".", revert = false) => {
  let arr = [];
  if (date instanceof Date) {
    arr[0] = date.getFullYear();

    let month = date.getMonth() + 1;
    if (month.toString().length === 1) month = "0" + month.toString();
    arr[1] = month;

    let day = date.getDate();
    if (day.toString().length === 1) day = "0" + day.toString();
    arr[2] = day;
  } else {
    arr = date.split("T")[0].split("-");
  }

  if (revert) return arr[0] + splitter + arr[1] + splitter + arr[2];
  return arr[2] + splitter + arr[1] + splitter + arr[0];
};
export const formatTime = (date, splitter = ":") => {
  let arr = date.split("T")[1].split(":");

  return arr[0] + splitter + arr[1];
};

export const truncateString = (s, w) => s.length > w ? s.slice(0, w) + "..." : s;

export const formatLongName = name => {
  return name.length > 25 ? name.substring(0, 14) + '...' + name.split('').reverse().join('').substring(0, 9).split('').reverse().join('') : name
}