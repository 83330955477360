import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

// import { useAuth } from "../context/provideAuth";
// import { useLocalStorage } from "../hooks/useLocalStorage";
// import request from "../managers/ajaxManager";
// import "../firebase-messaging-sw";
// import PopupToast from '../components/Notifications/PopupToast'

const Layout = () => {
  // const auth = useAuth();
  // const [FCMToken] = useLocalStorage('bpla-fcm-token')
  // const [FCMTokenOld, setFCMTokenOld] = useLocalStorage(
  //     'gis-river-fcm-token-old'
  // )
  // const dispatch = useDispatch();

  // useEffect(() => {
  //     if (FCMToken !== FCMTokenOld) {
  //         const data = {
  //             fcmToken: FCMToken,
  //             fcmOld: FCMTokenOld,
  //         }
  //
  //         request(
  //             '/user/fcmtoken',
  //             'PATCH',
  //             data,
  //             {
  //                 'YT-AUTH-TOKEN': `YourTar ${auth.token}`,
  //             },
  //             (response) => {
  //                 setFCMTokenOld(FCMToken)
  //             },
  //             (error) => {
  //                 console.error(error)
  //             }
  //         )
  //     }
  // }, [auth])

  useEffect(() => {}, []);
  return (
    <div className="App">
      <Outlet />
    </div>
  );
};

export default Layout;
