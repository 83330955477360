import { createStore } from "redux";
import { persistStore } from "redux-persist";
import persistCombineReducers from "redux-persist/es/persistCombineReducers";
import storage from "redux-persist/lib/storage";

import userReducer from "./userReducer";

const rootReducer = {
  user: userReducer,
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

let store = createStore(persistedReducer);
let persistor = persistStore(store);
let projectStorage = { store, persistor };

export default projectStorage;
