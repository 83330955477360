//prod
// const server = 'https://back.kedr.dev.yourtar.ru/';

//stage
export const server =
  process.env.REACT_APP_SERVER_URL ?? "https://back.kedr.dev.yourtar.ru";
const socket = 'wss://сервер.бпла.демо-стенд.рф/ws/';

//dev
// const server = 'http://localhost:8000/';
// const socket = 'http://127.0.0.1:8000/';

export const serverUrl = server + "/api";
export const socketUrl = socket;
export const version = "v0.1.0";
