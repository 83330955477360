import {ReactComponent as Mac} from "../static/svg/mac.svg";
import NavBarPublic from "../uiKit/navBarPublic/navBar";
import Footer from "../uiKit/footer/footer";
import Button from "../uiKit/button/button";
import {useAuth} from "../context/provideAuth";
import {useNavigate} from "react-router-dom";
import {useState, useEffect} from 'react';
import request from "../managers/ajaxManager";
import ActionLink from "../uiKit/actionLink/actionLink";
import {formatDate, truncateString} from "../managers/formatManager";
import Placeholder from '../static/img/placeholder.png';
import {server} from "../managers/parameters";

const Index = props => {
    const [articles, setArticles] = useState([]);
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        request(
            `/content/article/?page=0&limit=3&sortBy=id&orderBy=DESC`,
            "GET",
            {},
            {},
            (response) => {
                setArticles(response.data)
            }
        );
    }, []);

    return <div className={'publicPage'}>
        <div className={'publicPage__menuPadding'}/>
        <NavBarPublic/>
        <div className={'publicPage__promo'}>
            <h1>Измерение леса дронами</h1>
            <p>Автоматизированное решение для планирования и контроля заготовки древесины на основе данных с дронов</p>
            <Button size={'M'} type={'primary'}
                    text={auth.user ? 'Кабинет' : 'Войти'}
                    onClick={() => navigate(auth.user ? '/private/profile' : '/login')}/>
            <Mac/>
        </div>
        <div className={'publicPage__indexArticles'}>
            <div className={'publicPage__indexArticles__header'}>
                <h2>Последние статьи</h2>
                <ActionLink size={'M'} text={'Все статьи'} link={'/article'} noIcon={true}/>
            </div>
            <div className={'publicPage__indexArticles__grid'}>
                {articles[0] && <div onClick={e => {
                    e.preventDefault();
                    navigate('/article/' + (articles[0].alias && articles[0].alias.length > 0 ? articles[0].alias : articles[0].id))
                }}>
                    <img alt={'article_img_0'} src={articles[0].images && articles[0].images.length > 0 ? server + articles[0].images[0] : Placeholder}/>
                    {articles[0].createdAt && articles[0].createdAt.length > 0 &&
                        <p className={'publicPage__indexArticles__article__date'}>{formatDate(articles[0].createdAt)}</p>}
                    <p className={'publicPage__indexArticles__article__title'}>{articles[0].title}</p>
                    <p className={'publicPage__indexArticles__article__content'}>{truncateString(articles[0].content, 200)}</p>
                </div>}
                <div>
                    {articles[1] && <div className={'publicPage__indexArticles__grid__lineBlock'} onClick={e => {
                        e.preventDefault();
                        navigate('/article/' + (articles[1].alias && articles[1].alias.length > 0 ? articles[1].alias : articles[1].id))
                    }}>
                        <div>
                            <img alt={'article_img_1'} src={articles[1].images && articles[1].images.length > 0 ? server + articles[1].images[0] : Placeholder}/>
                        </div>
                        <div>
                            {articles[1].createdAt && articles[1].createdAt.length > 0 &&
                                <p className={'publicPage__indexArticles__article__date'}>{formatDate(articles[1].createdAt)}</p>}
                            <p className={'publicPage__indexArticles__article__title'}>{articles[1].title}</p>
                            <p className={'publicPage__indexArticles__article__content'}>{truncateString(articles[1].content, 100)}</p>
                        </div>
                    </div>}
                    {articles[2] && <div className={'publicPage__indexArticles__grid__lineBlock'} onClick={e => {
                        e.preventDefault();
                        navigate('/article/' + (articles[2].alias && articles[2].alias.length > 0 ? articles[2].alias : articles[2].id))
                    }}>
                        <div>
                            <img alt={'article_img_2'} src={articles[2].images && articles[2].images.length > 0 ? server + articles[2].images[0] : Placeholder}/>
                        </div>
                        <div>
                            {articles[2].createdAt && articles[2].createdAt.length > 0 &&
                                <p className={'publicPage__indexArticles__article__date'}>{formatDate(articles[2].createdAt)}</p>}
                            <p className={'publicPage__indexArticles__article__title'}>{articles[2].title}</p>
                            <p className={'publicPage__indexArticles__article__content'}>{truncateString(articles[2].content, 100)}</p>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default Index;