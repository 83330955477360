import {ReactComponent as RowLogo} from "../../static/svg/row_logo.svg";
import './footer.scss';

const FooterPublic = props => {
    return <div className={'footer'}>
        <div className={'footer__info'}>
            <div className={'footer__info__name'}>
                <RowLogo />
                <p>Автоматизированное решение для планирования и контроля заготовки древесины на основе данных с дронов</p>
            </div>
            <div className={'footer__info__contact'}>
                <p className={'footer__info__contact__header'}>Контактная информация</p>
                <p><a href={'mailto:mail@mail.ru'}>mail@mail.ru</a></p>
                <p><a href={'tel:+74959369992'}>+7 (495) 936-99-92</a></p>
            </div>
        </div>
        <div className={'footer__rights'}>
            © 2024 Таксация древостоев
        </div>
    </div>
}

export default FooterPublic;