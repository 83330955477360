import React, { createContext, useContext, useState } from "react";

const formContext = createContext({});

export const ProvideForm = (props) => {
  const form = useProvideForm();
  return (
    <formContext.Provider value={form}>{props.children}</formContext.Provider>
  );
};

const useForm = () => {
  return useContext(formContext);
};

const useProvideForm = () => {
  const [errors, setFormErrors] = useState({});

  const setErrors = (formId, error) => {
    setFormErrors({ ...errors, [formId]: error });
  };
  const clearError = (formId) => {
    const newErrors = { ...errors };
    delete newErrors[formId];
    setFormErrors(newErrors);
  };

  return {
    errors,
    setErrors,
    clearError,
  };
};

export { useForm };
