import PropTypes from "prop-types";
import "./inlineLoader.scss";

const InlineLoader = (props) => {
  InlineLoader.propTypes = {
    type: PropTypes.oneOf(["primary", "secondary", "outline"]),
    size: PropTypes.oneOf(["L", "M", "S", "XS"]),
    iconOnly: PropTypes.bool,
  };

  return (
    <div
      className={
        "inline-loader " +
        (props.size ? "size" + props.size + " " : "") +
        (props.type + " " ?? "primary ") +
        (props.iconOnly ? "size" + props.size + "--iconOnly " : "")
      }
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default InlineLoader;
