import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LeftSvg } from "../../static/svg/left.svg";
import "./actionLink.scss";

const ActionLink = (props) => {
  const navigate = useNavigate();
  ActionLink.propTypes = {
    size: PropTypes.oneOf(["M", "S"]),
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    link: PropTypes.string,
  };

  return (
    <div
      onClick={() => (props.link ? navigate(props.link) : navigate(-1))}
      className={
        "bpla_kit_action_link " +
        (props.className + " " ?? "") +
        (props.size ? "size" + props.size + " " : "")
      }
    >
      {!props.noIcon && <LeftSvg />}
      <span>{props.text}</span>
    </div>
  );
};

export default ActionLink;
