import PropTypes from "prop-types";

import { ReactComponent as CheckSvg } from "../../static/svg/check.svg";
import InlineLoader from "../loaders/inlineLoader/inlineLoader";
import "./button.scss";

const Button = (props) => {
  Button.propTypes = {
    type: PropTypes.oneOf(["primary", "secondary", "outline"]),
    size: PropTypes.oneOf(["L", "M", "S", "XS"]),
    text: PropTypes.string,
    success: PropTypes.bool,
    isSubmit: PropTypes.bool,
    iconOnly: PropTypes.bool,
    loading: PropTypes.bool,
    fullWidth: PropTypes.bool,
    icon: PropTypes.node,
  };

  return (
    <button
      type={props.isSubmit ? "submit" : "button"}
      disabled={props.disabled}
      onClick={!props.loading ? props.onClick : undefined}
      className={
        "bpla_kit_button " +
        (props.className + " " ?? "") +
        (props.type + " " ?? "primary ") +
        (props.size ? "size" + props.size + " " : "") +
        (props.fullWidth ? "fullWidth " : "") +
        (props.iconOnly ? "size" + props.size + "--iconOnly " : "") +
        (props.disabled ? "disabled " : "")
      }
    >
      {props.icon && <div className="bpla_kit_button">{props.icon}</div>}
      {props.loading && (
        <InlineLoader
          type={props.type}
          size={props.size}
          iconOnly={props.iconOnly}
        />
      )}
      {!props.loading && props.success && <CheckSvg />}
      {!props.loading && !props.iconOnly && (
        <span className="bpla_kit_button__text">{props.text}</span>
      )}
    </button>
  );
};
export default Button;
