import {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";

import {useAuth} from "../../context/provideAuth";
import {ReactComponent as RowLogo} from "../../static/svg/row_logo.svg";
import {ReactComponent as CloseSvg} from "../../static/svg/close.svg";
import {ReactComponent as MenuMobileSvg} from "../../static/svg/menu-mobile.svg";

import "./navBar.scss";
import Button from "../button/button";

const NavBarPublic = (props) => {
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();

    return <div className={"bpla_kit_nav_bar_public " + (isOpenMobileMenu ? 'active' : '')}>
        <div className={'bpla_kit_nav_bar_public__header'}>
            <div>
                <RowLogo className={'logo'} onClick={() => navigate('/')} />
            </div>
            <div>
                <NavLink to={'/article'} className={"bpla_kit_nav_bar_public__header__link desktop" + (window.location.pathname.includes('/article') && !window.location.pathname.includes('/services') ? ' activeLink' : '')}>
                    Статьи
                </NavLink>
                <NavLink to={'/article/category/services'} className={"bpla_kit_nav_bar_public__header__link desktop" + (window.location.pathname.includes('/article/category/services') ? ' activeLink' : '')}>
                    Услуги
                </NavLink>
                <Button size={'M'} type={'primary'} className={'desktop'}
                        text={auth.user ? 'Кабинет' : 'Войти'} onClick={() => navigate(auth.user ? '/private/profile' : '/login')} />

                {isOpenMobileMenu ? (
                    <CloseSvg className={'mobile'} onClick={() => setIsOpenMobileMenu(false)}/>
                ) : (
                    <MenuMobileSvg className={'mobile'} onClick={() => setIsOpenMobileMenu(true)}/>
                )}
            </div>
        </div>
        {isOpenMobileMenu && <div className={'bpla_kit_nav_bar_public__subMenu mobile'}>
            <NavLink to={'/articles'} className={"bpla_kit_nav_bar_public__subMenu__link" + (window.location.pathname.includes('/article') && !window.location.pathname.includes('/services') ? ' activeLink' : '')}>
                Статьи
            </NavLink>
            <NavLink to={'/services'} className={"bpla_kit_nav_bar_public__subMenu__link" + (window.location.pathname.includes('/article/category/services') ? ' activeLink' : '')}>
                Услуги
            </NavLink>
            <NavLink to={auth.user ? '/private/profile' : '/login'} className="bpla_kit_nav_bar_public__subMenu__link">
                {auth.user ? 'Кабинет' : 'Войти'}
            </NavLink>
        </div>}
    </div>
};

export default NavBarPublic;
