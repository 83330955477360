import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import ProvideAuth from "./context/provideAuth";
import { ProvideForm } from "./context/provideForm";
import request from "./managers/ajaxManager";
import 'leaflet/dist/leaflet.css';
import Layout from "./routes/Layout";
import IndexPage from "./pages/Index";

const PublicRoutes = lazy(() => import("./routes/Public"));
const PrivateRoutes = lazy(() => import("./routes/Private"));
const PageLoader = lazy(() => import("./uiKit/loaders/pageLoader/pageLoader"));

const App = () => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user.token) {
      request(
        `/user/`,
        "GET",
        {},
        {
          "YT-AUTH-TOKEN": user.token,
        },
        (response) => {
          dispatch({ type: "UPDATE", payload: response.data });
        },
        (error) => {
          alert(error.message);
        }
      );
    }
  }, []);

  return (
    <ProvideAuth>
      <ProvideForm>
        <Router>
          <Suspense fallback={<PageLoader />}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<IndexPage />} />

                <Route
                  path="private/*"
                  element={
                    user.user && user.token ? (
                      <PrivateRoutes />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />

                <Route path="/*" element={<PublicRoutes />} />

                {/*<Route path="*" element={<NoMatch to="/login" />} />*/}
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </ProvideForm>
    </ProvideAuth>
  );
};

export default App;
