import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
// import errorBoundary from "./components/errorBoundary/errorBoundary";
import projectStorage from "./reducers/rootReducer";
import reportWebVitals from "./reportWebVitals";
import PageLoader from "./uiKit/loaders/pageLoader/pageLoader";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <errorBoundary>
  <Provider store={projectStorage.store}>
    <PersistGate persistor={projectStorage.persistor}>
      <Suspense loading={<PageLoader />}>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>
  // </errorBoundary>,
);

reportWebVitals();
